.relative {
  position: relative;
}

.resetList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mainwidth {
  width: 300px;
  word-wrap: break-word;
  margin: 0 auto;
  padding: 0 10px;
}

.border-radius-bottom {
  -moz-border-radius-bottomright: 12px;
  -moz-border-radius-bottomleft: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.absolute {
  z-index: 2;
  position: absolute;
}

.mask {
  z-index: 20;
  position: relative;
}

.ontop {
  z-index: 10;
  background: #fff;
  position: relative;
  height: auto !important;
}

#kreis-1, #kreis-2, #kreis-3, #kreis-4, #kreis-5, #kreis-6 {
  display: none;
}

img {
  width: 100%;
}

.disable-mobile {
  display: none;
}

.visible-tablet, .visible-desktop {
  display: none !important;
}

.visible-mobile {
  display: block !important;
}

.clear {
  clear: both;
}

body {
  background: #fff;
  font-family: Noto Sans, sans-serif;
  font-size: 1em;
  line-height: 1.5em;
}

h1 {
  margin: 0;
  font-size: 2em;
  line-height: 1.5em;
}

h2 {
  margin: 0;
  font-size: 1.3em;
  font-style: italic;
  line-height: 1.5em;
}

h3 {
  margin: 0;
  font-size: 1.2em;
  font-style: italic;
  line-height: 1.5em;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: disc;
}

ul {
  margin: 0 0 20px 15px;
}

ul li {
  line-height: 1.5em;
}

dl {
  margin: 0;
  padding: 0;
  list-style: none;
}

dt {
  margin: 20px 0 0;
  font-size: 1.2em;
  font-style: italic;
  font-weight: 700;
  line-height: 1.5em;
}

dd {
  background: url("list.1a911070.png") 0 10px no-repeat;
  margin: 0;
  padding: 0 0 0 10px;
  line-height: 1.5em;
  list-style: disc;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

div.cols div {
  float: none;
  padding-bottom: 15px;
}

div.cols div.last {
  float: none;
  margin: 0;
}

.row-2, .row-3, .row-4, .row-5, .row-6, .row-7, .row-8, .row-9, .row-10, .row-11, .row-12, .row-13, .row-14 {
  width: 300px;
}

div.mainwidth {
  width: 300px;
  word-wrap: break-word;
  margin: 0 auto;
  padding: 0 10px;
}

header {
  height: 116px;
  width: 100%;
  z-index: 2000;
  background: url("headerback.65c3651b.png") 0 0 repeat-x;
  position: fixed;
  top: 0;
}

section {
  min-height: 200px;
  z-index: 20;
  position: relative;
}

section#home {
  color: #54758c;
  z-index: 10;
  background: #fff;
  margin: 116px 0 5px;
  padding: 0;
  box-shadow: 0 5px 1px #999;
}

section#home div.fair {
  display: none;
}

section#home p.abstand {
  margin-top: 0;
}

section#space {
  z-index: 1;
  background: #fafbf1;
  margin: 0;
  padding: 0;
  display: none;
  height: 10px !important;
}

section#angebot, section#netzwerk {
  color: #fff;
  background: #54758c;
  margin: 116px 0;
  padding: 56px 0 70px;
}

section#angebot {
  background: #54758c;
  margin-top: 0;
}

section#profil, section#referenzen, section#kontakt {
  color: #54758c;
  background: #fff;
  margin: 116px 0;
  padding: 56px 0 70px;
}

section#referenzen {
  padding: 0 0 70px;
}

section#impressum {
  color: #fff;
  background: #575759;
  margin: 116px 0 0;
  padding: 56px 0 70px;
}

section#home a, section#profil a, section#referenzen a, section#kontakt a {
  color: #54758c;
}

section#impressum a, section#angebot a, section#netzwerk a {
  color: #fff;
}

div#menubutton {
  float: right;
  cursor: pointer;
  white-space: nowrap;
  text-indent: 999em;
  width: 30px;
  height: 22px;
  background: url("knopf-telefon.837f6115.png") 0 0 no-repeat;
  margin-top: 15px;
  overflow: hidden;
}

nav {
  position: absolute;
  top: 116px;
}

nav ul {
  -moz-border-radius-bottomright: 12px;
  -moz-border-radius-bottomleft: 12px;
  width: 300px;
  height: auto;
  float: none;
  background: #575759;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin: 0;
  padding: 0 0 5px;
  list-style: none;
}

nav ul li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}

nav ul li a:link, nav ul li a:visited {
  color: #fff;
  text-transform: uppercase;
  padding: 8px 0 8px 25px;
  font-size: 16px;
  text-decoration: none;
  display: block;
}

nav ul li a.current, nav ul li a:hover, nav ul li a:active {
  background: url("navact.a9ac7e71.png") 5px 15px no-repeat;
}

div#logo {
  float: left;
}

div#logo a.logo {
  height: 79px;
  width: 250px;
  white-space: nowrap;
  text-indent: 999em;
  background: url("logo2.df22bdbc.png") 0 100% no-repeat;
  display: block;
  overflow: hidden;
}

/*# sourceMappingURL=index.d171f14c.css.map */
