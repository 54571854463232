/*####################*/
/*General Colors and Variables*/
/*####################*/
@black: #000;
@grey: #575759;
@white: #fff;
@blue:#54758c;
@fair: #fafbf1;

@fullwidth:300px;

@colwidth:300px;
@gutterwidth:0px;
@boxpaddingbottom:70px;
@boxpaddingtop:56px;
@boxmargin:116px;

@basefontSize:1em;
@largeFont: @basefontSize * 2;
@mediumFont: @basefontSize * 1.3;
@baselineHeight: 1.5em;


/*####################*/
/*define the grid*/
/*####################*/
@wide1:@colwidth;
@wide2:(@colwidth + @gutterwidth);
@wide3:(@colwidth + @gutterwidth);
@wide4:(@colwidth + @gutterwidth);
@wide5:(@colwidth + @gutterwidth);
@wide6:(@colwidth + @gutterwidth);
@wide7:(@colwidth + @gutterwidth);
@wide8:(@colwidth + @gutterwidth);
@wide9:(@colwidth + @gutterwidth);
@wide10:(@colwidth + @gutterwidth);
@wide11:(@colwidth + @gutterwidth);
@wide12:(@colwidth + @gutterwidth);
@wide13:(@colwidth + @gutterwidth);
@wide14:(@colwidth + @gutterwidth);


/*####################*/
/*Mixins*/
/*####################*/
.relative{
	position:relative;
}
.resetList{
	margin:0;
	padding:0;
	list-style:none;
}
.mainwidth{
	width:@fullwidth;
	padding:0 10px 0 10px;
	margin:0 auto;
	word-wrap: break-word;
}
.border-radius-bottom{
	-webkit-border-bottom-right-radius: 12px;
	-webkit-border-bottom-left-radius: 12px;
	-moz-border-radius-bottomright: 12px;
	-moz-border-radius-bottomleft: 12px;
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
}

.box-shadow (@x: 0, @y: 0, @blur: 1px, @color: #999) {
  box-shadow: @arguments;
  -moz-box-shadow: @arguments;
  -webkit-box-shadow: @arguments;
}

/*####################*/
/*Scroll stuff*/
/*####################*/
.absolute{
	position:absolute;
	z-index:2;
}
.mask{
	position:relative;
	z-index:20;
}
.ontop{
	position:relative;
	z-index:10;
	background:@white;
	height:auto!important;
}
#kreis-1, #kreis-2, #kreis-3, #kreis-4, #kreis-5, #kreis-6 {
	display:none;
}

img{
	width:100%;
}

/*####################*/
/*Show and Hidem and Helper*/
/*####################*/
.disable-mobile{
	display:none;
}
.visible-tablet, .visible-desktop{
	display:none!important;
}
.visible-mobile{
	display:block!important;
}
.clear{
	clear:both;
}
/*####################*/
/*General Formats*/
/*####################*/
body{
	font-family: 'Noto Sans', sans-serif;
	background:@white;
	font-size:@basefontSize;
	line-height:@baselineHeight;
}
h1{
	font-size:@largeFont;
	margin:0;
	line-height:@baselineHeight;
}
h2{
	font-size:@mediumFont;
	margin:0;
	font-style:italic;
	line-height:@baselineHeight;
}
h3{
	font-size:@basefontSize * 1.2;
	line-height:@baselineHeight;
	margin:0;
	font-style:italic;
}
ul, li{
	.resetList;
	list-style:disc;
}
ul{
	margin:0 0 20px 15px;
}
ul li{
	line-height:@baselineHeight;
}

dl{
	.resetList;
}

dt{
	font-size:@basefontSize * 1.2;
	line-height:@baselineHeight;
	margin:20px 0 0 0;
	font-style:italic;
	font-weight:700;	
}
dd{
	list-style:disc;
	margin:0;
	padding:0 0 0 10px;
	line-height:@baselineHeight;
	background: url(../../img/list.png) no-repeat 0px 10px;
}

a{
	text-decoration:none;
}
a:hover{
	text-decoration:underline;
}

/*####################*/
/* General Boxes*/
/*####################*/
/*2 cols*/
div.cols div{
	float:none;
	padding-bottom:15px;
}
div.cols div.last{
	float:none;
	margin: 0;
}

.row-2{
	width:@wide2;
}
.row-3{
	width:@wide3;
}
.row-4{
	width:@wide4;
}
.row-5{
	width:@wide5;
}
.row-6{
	width:@wide6;
}
.row-7{
	width:@wide7;
}
.row-8{
	width:@wide8;
}
.row-9{
	width:@wide9;
}
.row-10{
	width:@wide10;
}
.row-11{
	width:@wide11;
}
.row-12{
	width:@wide12;
}
.row-13{
	width:@wide13;
}
.row-14{
	width:@wide14;
}
/*####################*/
/* General Boxes*/
/*####################*/

div.mainwidth{
	.mainwidth;
}
header{
	position:fixed;
	background: url(../../img/headerback.png) repeat-x top left;
	height:116px;
	width:100%;
	z-index:2000;
	top:0;
}
section{
	min-height:200px;
	position:relative;
	z-index:20;
}

section#home{
	background:@white;
	margin:@boxmargin 0 5px 0;
	padding:0;
	color:@blue;
	.box-shadow(0px, 5px);
	z-index:10;
}
section#home div.fair{
	display:none;
}
section#home p.abstand{
	margin-top:0;
}
section#space{
	background:@fair;
	height:10px!important;
	margin:0;
	padding:0;
	z-index:1;
	display:none;
}
section#angebot, section#netzwerk{
	background:@blue;
	color:@white;
	padding:@boxpaddingtop 0 @boxpaddingbottom 0;
	margin:@boxmargin 0 @boxmargin 0;
}
section#angebot{
	margin-top:0;
	background:@blue url(../../img/border.png) repeat-x top left;
	background:@blue;
}
section#profil, section#referenzen, section#kontakt{
	background:@white;
	color:@blue;
	padding:@boxpaddingtop 0 @boxpaddingbottom 0;
	margin:@boxmargin 0 @boxmargin 0;
}
section#referenzen{
	padding:0 0 @boxpaddingbottom 0;
}
section#impressum{
	background:@grey;
	color:@white;
	padding:@boxpaddingtop 0 @boxpaddingbottom 0;
	margin:@boxmargin 0 0 0;
}

section#home a, section#profil a, section#referenzen a, section#kontakt a{
	color:@blue;
}
section#impressum a, section#angebot a, section#netzwerk a{
	color:@white;
}
/*####################*/
/*Nav*/
/*####################*/
div#menubutton{
	float:right;
	cursor:pointer;
	background: url(../../img/knopf-telefon.png) no-repeat top left;
	overflow:hidden;
	white-space: nowrap;
	text-indent: 999em;
	width:30px;
	height:22px;
	margin-top:15px;
}

nav{
	position:absolute;
	top:116px;
}
nav ul{
	.resetList;
	background:@grey;
	.border-radius-bottom;
	width:300px;
	height:auto;
	padding-top:20px;
	float:none;
	padding:0 0 5px 0;
}
nav ul li{
	.resetList;
	display:block;
}
nav ul li a:link, nav ul li a:visited{
	color:@white;
	padding:8px 0 8px 25px;
	text-decoration:none;
	text-transform:uppercase;
	font-size:16px;
	display:block;
}
nav ul li a.current, nav ul li a:hover, nav ul li a:active{
	background: url(../../img/navact.png) no-repeat 5px 15px;
}

/*####################*/
/*Logo*/
/*####################*/
div#logo{
	float:left;
}
div#logo a.logo{
	display:block;
	height:79px;
	width:250px;
	background:url(../../img/logo2.png) no-repeat bottom left;
	overflow:hidden;
	white-space: nowrap;
	text-indent: 999em;
}


